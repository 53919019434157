<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="userModal"
      :title="$t('Add Merchant')"
      :buttons-hidden="true">
        <div>
          <div class="vx-row mt-5">
            <div class="vx-col">
              <h4>{{$t('Merchant Information')}}</h4>
            </div>
          </div>
          <div>
            <input type="file" @change="changeImage" class="hidden" ref="fileInput" accept="image/*">
            <div
              @click="$refs.fileInput.click()"
              @drop="handleDrop"
              @dragover="handleDragover"
              @dragenter="handleDragover"
              class="w-4/5 m-auto mt-10 mb-10 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
              <div v-if="image === ''">
                <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
                <span>{{$t('Drop Image or')}} </span>
                <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
              </div>
              <div v-else>
                <img class="w-20 h-20" :src="image"/>
              </div>
            </div>
          </div>
          <div class="vx-row mt-10">
            <div class="vx-col w-1/2">
              <vs-input v-validate="{required: true, regex: /^[ \u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9.-_]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9-_.]*$/ }" :label-placeholder="$t('Legal Name')" name="name" v-model="merchant.name" class="w-full"/>
              <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <vs-input name="display name" :label-placeholder="$t('Merchant Display Name')" v-model="merchant.display_name" class="w-full"/>
              <span class="text-danger text-sm" v-show="errors.has('display name')">{{ errors.first('display name') }}</span>
            </div>
          </div>
          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <vs-input v-validate="'required|url:require_protocol'" name="store url" label-placeholder="https://www.example.com" v-model="merchant.store_url" class="w-full"/>
              <span class="text-danger text-sm" v-show="errors.has('store url')">{{ errors.first('store url') }}</span>
            </div>
          </div>
          <div class="vx-row mt-4">
            <div class="vx-col w-1/2">
              <vs-input v-validate="'required|email'" name="email" :label-placeholder="$t('Email')" v-model="merchant.store_email" class="w-full"/>
              <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <vs-input v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" :label-placeholder="$t('Store Phone')" name="store phone" v-model="merchant.store_phone" class="w-full"/>
              <span class="text-danger text-sm" v-show="errors.has('store phone')">{{ errors.first('store phone') }}</span>
            </div>
            <div class="vx-col w-1/2 mt-8">
              <v-select @search="handleAccountManagersSearch" @keyup.enter.native="handleAccountManagersSearch" search autocomplete="nofill" class="select-large w-full" name="account manager"
              v-model="merchant.account_manager" :placeholder="$t('Account Manager')" label="name" :options="accountManagers"/>
            </div>
            <div class="vx-col w-1/2 mt-8">
              <v-select @search="handleSalesSearch" @keyup.enter.native="handleSalesSearch" search autocomplete="nofill" class="select-large w-full" name="sales"
              v-model="merchant.sale" :placeholder="$t('Sales')" label="name" :options="sales"/>
            </div>
            <div class="vx-col w-full mt-4">
              <vs-input v-validate="'required'" name="address 1" :label-placeholder="$t('Address 1')" v-model="merchant.line_1" class="w-full"/>
              <span class="text-danger text-sm" v-show="errors.has('address 1')">{{ errors.first('address 1') }}</span>
            </div>
            <div class="vx-col w-full mt-4">
              <vs-input v-validate="'required'" name="address 2" :label-placeholder="$t('Address 2')" v-model="merchant.line_2" class="w-full"/>
              <span class="text-danger text-sm" v-show="errors.has('address 2')">{{ errors.first('address 2') }}</span>
            </div>
            <div class="vx-col w-full">
              <vs-input name="address 2" :label-placeholder="$t('Address 3')" v-model="merchant.line_3" class="mt-10 w-full"/>
            </div>
            <div class="vx-col w-1/2">
              <v-select autocomplete="nofill" class="select-large mt-10 w-full" name="governorate" v-model="merchant.merchant_governorate" v-validate="'required'" :placeholder="$t('Governorate')" label="name" :options="governorates"/>
              <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <v-select autocomplete="nofill" class="select-large mt-10 w-full" name="city" v-model="merchant.merchant_city"
                v-validate="'required'" :placeholder="$t('City')" label="name" :options="cities"/>
              <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <v-select autocomplete="nofill" class="select-large mt-10 w-full" v-model="merchant.merchantZone" v-validate="'required'"
                name="zone" :placeholder="$t('Zone')" label="name" :options="zones"/>
              <span class="text-danger text-sm" v-show="errors.has('zone')">{{ errors.first('zone') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <vs-input name="Zip Code" :label-placeholder="$t('Zip Code')" v-model="merchant.merchant_zip_code" class="mt-10 w-full"/>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col">
              <h4>{{$t('Industry')}}</h4>
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col w-1/3 mt-2">
              <label class="font-semibold">{{$t('Industry')}}</label>
            </div>
            <div class="vx-col w-2/3">
              <v-select autocomplete="nofill" class="mt-2 w-full" v-model="industry" v-validate="'required'"
                name="industry" :placeholder="$t('Industry')" label="text" :options="industries"/>
              <span class="text-danger text-sm" v-show="errors.has('industry')">{{ errors.first('industry') }}</span>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col">
              <h4>{{$t('Subscription')}}</h4>
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col w-1/3 mt-2">
              <label class="font-semibold">{{$t('Subscription')}}</label>
            </div>
            <div class="vx-col w-2/3">
              <v-select autocomplete="nofill" class="mt-2 w-full" v-model="subscription" v-validate="'required'"
                name="subscription" :placeholder="$t('Subscription')" label="name" :options="subscriptions"/>
              <span class="text-danger text-sm" v-show="errors.has('subscription')">{{ errors.first('subscription') }}</span>
            </div>
          </div>
          <div class="vx-row mb-2" v-if="subscription.id === 2">
            <div class="vx-col w-1/3" v-for="day in days" :key="day.index">
              <vs-checkbox v-validate="'required'" class="mb-2" name="days" v-model="pickupDays" :vs-value="day.id">{{day.day}}</vs-checkbox>
            </div>
          </div>
          <span class="text-danger text-sm block" v-show="errors.has('days')">{{errors.first('days')}}</span>

          <div class="vx-row mt-5">
            <div class="vx-col">
              <h4>{{$t('Pricing')}}</h4>
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col w-1/3 mt-2">
              <label class="mt-10 font-semibold">{{$t('Pricing Package')}}</label>
            </div>
            <div class="vx-col w-2/3">
              <v-select autocomplete="nofill" class="select-large w-full" name="pricing package" v-model="merchant.pricingPackage"
                v-validate="'required'" :placeholder="$t('Pricing Package')" label="name" :options="pricingPackages"/>
              <span class="text-danger text-sm" v-show="errors.has('pricing package')">{{ errors.first('pricing package') }}</span>
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col w-1/3 mt-2">
              <label class="mt-10 font-semibold">{{$t('Committed Volume')}}</label>
            </div>
            <div class="vx-col w-2/3">
              <vs-input v-validate="'required|numeric'" :placeholder="$t('Committed Volume')" name="committed volume" v-model="merchant.committed_volume" class="mt-2 w-full"/>
              <span class="text-danger text-sm" v-show="errors.has('committed volume')">{{ errors.first('committed volume') }}</span>
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col w-1/3">
              <label class="font-semibold">{{$t('Pickup Time')}}</label>
            </div>
            <div class="vx-col w-2/3">
              <vs-radio v-validate="'required'" name="pickup time" class="mr-4" v-model="merchant.pickup_time" vs-value="1">{{$t('1 pm')}}</vs-radio>
              <vs-radio name="pickup time" v-model="merchant.pickup_time" vs-value="2">{{$t('3 pm')}}</vs-radio>
              <p class="text-danger text-sm" v-show="errors.has('pickup time')">{{ errors.first('pickup time') }}</p>
            </div>
          </div>
          <template v-if="$store.state.AppActiveUser.userRole === 'admin'">
            <div class="vx-row mt-5">
              <div class="vx-col">
                <h4>{{$t('Cash Processing')}}</h4>
              </div>
            </div>
            <div class="vx-row mb-2">
              <div class="vx-col w-1/3 mt-2">
                <label class="font-semibold">{{$t('Cash processing fees')}}</label>
              </div>
              <div class="vx-col w-2/3">
                <vs-input v-validate="'decimal'" :placeholder="$t('Cash processing fees')" name="cash processing fees" v-model="merchant.cash_processing_fees" class="mt-2 w-full"/>
                <span class="text-danger text-sm" v-show="errors.has('cash processing fees')">{{ errors.first('cash processing fees') }}</span>
              </div>
              <div class="vx-col w-1/3 mt-2">
                <label class="font-semibold">{{$t('Cash processing fees max')}}</label>
              </div>
              <div class="vx-col w-2/3">
                <vs-input v-validate="'decimal'" :placeholder="$t('Cash processing fees max')" name="cash processing fees max"  v-model="merchant.cash_processing_fees_max" class="mt-2 w-full"/>
                <span class="text-danger text-sm" v-show="errors.has('cash processing fees max')">{{ errors.first('cash processing fees max') }}</span>
              </div>
              <div class="vx-col w-1/3 mt-2">
                <label class="font-semibold">{{$t('Cash processing percentage')}}</label>
              </div>
              <div class="vx-col w-2/3">
                <vs-input v-validate="'decimal'" :placeholder="$t('Cash processing percentage')" name="cash processing percentage"  v-model="merchant.cash_processing_percentage" class="mt-2 w-full"/>
                <span class="text-danger text-sm" v-show="errors.has('cash processing percentage')">{{ errors.first('cash processing percentage') }}</span>
              </div>
              <div class="vx-col w-1/3 mt-2">
                <label class="font-semibold">{{$t('Cash processing threshold')}}</label>
              </div>
              <div class="vx-col w-2/3">
                <vs-input v-validate="'decimal'" :placeholder="$t('Cash processing threshold')" name="cash processing threshold"  v-model="merchant.cash_processing_threshold" class="mt-2 w-full"/>
                <span class="text-danger text-sm" v-show="errors.has('cash processing threshold')">{{ errors.first('cash processing threshold') }}</span>
              </div>
              <div class="vx-col w-1/3 mt-2">
                <label class="font-semibold">{{$t('Refunds Credit Limit')}}</label>
              </div>
              <div class="vx-col w-2/3">
                <vs-input v-validate="'decimal'" :placeholder="$t('Refunds Credit Limit')" name="refunds credit limit" v-model="merchant.refunds_credit_limit" class="mt-2 w-full"/>
                <span class="text-danger text-sm" v-show="errors.has('refunds credit limit')">{{ errors.first('refunds credit limit') }}</span>
              </div>
              <div class="vx-col w-1/3 mt-2">
                <label class="font-semibold">{{$t('Refunds Enabled')}}</label>
              </div>
              <div class="vx-col w-2/3">
                <vs-switch name="refunds enabled" v-model="merchant.refunds_enabled" class="mt-2"/>
                <span class="text-danger text-sm" v-show="errors.has('refunds enabled')">{{ errors.first('refunds enabled') }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="addMerchant" class="active-btn btn">{{ $t('Add') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData.js'
import common  from '@/assets/utils/common'

export default {
  props:['userModal'],
  data () {
    return {
      type: 'add',
      uploadShipmentsModal: false,
      merchant: {
        name: '',
        pickup_time: '1',
        display_name: '',
        subscription: {}
      },
      industries: [],
      industry: '',
      file_url: '',
      governorates: [],
      cities: [],
      zones: [],
      subscriptions: [],
      subscription: {},
      days: [],
      pricingPackages: [],
      image: '',
      pickupDays: [],
      merchantID: '',
      accountManagers: [],
      sales: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' '
    }
  },
  watch: {
    '$route.params.lang' () {
      this.industries = common.getIndustries(this)
    },
    'merchant.merchant_governorate' (val) {
      if (this.merchant.merchant_governorate) {
        this.loadCities(val.id)
      }
    },
    'merchant.merchant_city' (val) {
      if (this.merchant.merchant_city) {
        this.loadZones(val.id)
      }
    },
    'searchVal' (val) {
      if (val === '') {
        this.accountManagers = []
        this.sales = []
      }
    },
    'userModal' (val) {
      if (val) {
        this.merchant = {}
        this.loadGovernorates()
        this.loadSubscriptions()
        this.loadPricingPackages()
      }
    }
  },
  methods: {
    changeImage () {
      const [file] = this.$refs.fileInput.files
      if (file) {
        this.image = URL.createObjectURL(file)
      }
      const formData = new FormData()
      formData.append('file', this.$refs.fileInput.files[0])

      sendRequest(false, false, this, 'api/v1/upload-file/', 'post', formData, true,
        (response) => {
          this.file_url = response.data.file_url
        }
      )
    },
    handleAccountManagersSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadAccountManagersSearch)
    },
    loadAccountManagersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadAccountManagers()
      }
    },
    loadAccountManagers () {
      this.searchInProgress = true
      const query = `?search=${this.searchVal}`
      sendRequest(false, false, this, `api/v1/users/customers-support/${query}`, 'get', null, true,
        (response) => {
          response.data.results.forEach(item => {
            item.name = `${item.first_name} ${item.last_name}`
            this.accountManagers.push(item)
          })
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    handleSalesSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadSalesSearch)
    },
    loadSalesSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadSales()
      }
    },
    loadSales () {
      this.searchInProgress = true
      const query = `?search=${this.searchVal}`
      sendRequest(false, false, this, `api/v1/users/sales/${query}`, 'get', null, true,
        (response) => {
          response.data.results.forEach(item => {
            item.name = `${item.first_name} ${item.last_name}`
            this.sales.push(item)
          })
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadPricingPackages () {
      sendRequest(false, false, this, 'api/v1/pricing-packages/?limit=100', 'get', null, true,
        (response) => {
          this.pricingPackages = []
          response.data.results.forEach(element => {
            if (element.status === 'ready' && element.id === 372) {
              this.pricingPackages.push(element)
            }
          })
        }
      )   
    },
    loadSubscriptions () {
      sendRequest(false, false, this, 'api/v1/subscriptions/', 'get', null, true,
        (response) => {
          this.subscriptions = response.data
          this.subscription = this.subscriptions[0]
          this.loadSubscriptionsDays()
        }
      )
    },
    loadSubscriptionsDays () {
      sendRequest(false, false, this, 'api/v1/subscriptions/pickup-days/', 'get', null, true,
        (response) => {
          this.days = response.data
        }
      )
    },
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
      })
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
      })
    },
    addMerchant () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.file_url !== '') {
            this.merchant.logo = this.file_url
          }
          this.merchant.address = {
            line_1: this.merchant.line_1,
            line_2: this.merchant.line_2,
            line_3: this.merchant.line_3,
            zone: this.merchant.merchantZone.id,
            zip_code: this.merchant.merchant_zip_code
          }
          this.merchant.industry = this.industry.value
          this.merchant.pricing_package = this.merchant.pricingPackage.id
          this.merchant.subscription = this.subscription.id
          if (this.subscription.id === 1) {
            delete this.merchant.pickup_days
          } else  {
            this.merchant.pickup_days = this.pickupDays
          }
          this.merchant.pickup_time =  this.merchant.pickup_time ? Number(this.merchant.pickup_time) : 1
          this.merchant.billing_days = [2]
          this.merchant.account_manager = this.merchant.account_manager ? this.merchant.account_manager.id : null
          this.merchant.sales = this.merchant.sale ? this.merchant.sale.id : ''
          if (!this.isOnline) this.$emit('userModal', false)
          this.merchant.store_email = this.merchant.store_email.toLowerCase()
          sendRequest(false, false, this, 'api/v1/merchants/', 'post', this.merchant, true,
            (response) => {
              this.merchantID = response.data.id
              this.addPickupPoint()
              this.addReturnPoint()
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Merchant'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.merchant = {}
              this.$emit('userModal', false)
              this.$emit('loadMerchants')
            }
          )
        }
      })
    },
    addPickupPoint () {
      const address = {
        address: this.merchant.address,
        is_default: true
      }
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/pickup-points/`, 'post', address, true,
        () => {}
      )
    },
    addReturnPoint () {
      const address = {
        address: this.merchant.address,
        is_default: true
      }
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/return-points/`, 'post', address, true,
        () => {}
      )
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isImage(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .png, .jpg suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
    },
    isImage (file) {
      return /\.(png|jpg)$/.test(file.name)
    },
    closeModal () {
      this.merchant = {}
      this.image = ''
      this.$emit('userModal', false)
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  created () {
    this.industries = common.getIndustries(this)
  }
}
</script>
